import React, { useEffect, useState } from "react";
// import "./App.css";
import { Card, Col, Container, Form, Button, Row } from "react-bootstrap";
import { db } from "../firebase";
import { getAuth } from "@firebase/auth";
import * as yup from "yup";
import {
  addDoc,
  collection,
  query,
  where,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { Formik, useFormikContext } from "formik";
import DatePicker from "react-date-picker";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import { date } from "yup/lib/locale";
import { ToastContainer, toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import "react-toastify/dist/ReactToastify.min.css";
import "react-date-picker/dist/DatePicker.css";

import { geticonTiempo } from "../Utils/Utils";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  username: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  zip: yup.string().required(),
  file: yup.mixed().required(),
  terms: yup.bool().required().oneOf([true], "terms must be accepted"),
});

function SelectRestaurantes(props) {
  const { t, i18n } = useTranslation();
  if (props.admin == true) {
    // console.log("admin");
    return (
      <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>{t("home.restaurante")}</Form.Label>
        <Form.Select
          aria-label="Restaurant"
          value={props.selectRestaurant}
          onChange={props.handleOnChangeselect}
        >
          <option disabled selected value>
            Elige una opción
          </option>
          <optgroup label="Lobito">
            <option value="LobitoMarbella">Lobito Marbella</option>
            <option value="LobitoMadrid">Lobito Madrid</option>
            <option value="LobitoDoha">Lobito Doha</option>
            <option value="LobitoLaFinca">Lobito La Finca</option>
          </optgroup>
          <optgroup label="BIBO">
            <option value="BIBOMarbella">BIBO Marbella</option>
            <option value="BIBODOHA">BIBO DOHA</option>
            <option value="BIBOMadrid">BIBO Madrid</option>
            <option value="BIBOTarifa">BIBO Tarifa</option>
            <option value="BIBOIbiza">BIBO Ibiza</option>
            <option value="BIBOLondon">BIBO London</option>
          </optgroup>
          <optgroup label="Leña">
            <option value="LenaMarbella">Leña Marbella</option>
            <option value="LenaMadrid">Leña Madrid</option>
            <option value="LenaDubai">Leña Dubai</option>
            <option value="LenaBarcelona">Leña Barcelona</option>
          </optgroup>
          <optgroup label="Smoked Room">
            <option value="SmokedRoom">Smoked Room Madrid</option>
            <option value="SmokedRoomDubai">Smoked Room Dubai</option>
          </optgroup>
          <optgroup label="Babette">
            <option value="BabetteMarbella">Babette Marbella</option>
          </optgroup>
          <optgroup label="CasaDani">
            <option value="CasaDaniNY">Casa Dani NY</option>
          </optgroup>
          <optgroup label="PolloVerde">
            <option value="PolloVerdeNY">Pollo Verde NY</option>
          </optgroup>
          <optgroup label="Alelí">
            <option value="AleliMarbella">Alelí Marbella</option>
            <option value="AleliBudapest">Alelí Budapest</option>
          </optgroup>
          <optgroup label="Kemuri">
            <option value="KemuriMarbella">Kemuri Marbella</option>
          </optgroup>
          {/* <optgroup label="Kurai">
            <option value="KuraiMarbella">Kurai Marbella</option>
          </optgroup> */}
          <optgroup label="Tragabuches">
            <option value="TragabuchesMarbella">Tragabuches Marbella</option>
            <option value="TragabuchesMadrid">Tragabuches Madrid</option>
          </optgroup>
          <optgroup label="Coleccionista">
            <option value="ColeccionistaMadrid">El Coleccionista Madrid</option>
          </optgroup>
          <optgroup label="Lacabane">
            <option value="LacabaneMarbella">La Cabane Marbella</option>
            <option value="PiscinaLacabaneMarbella">
              La Cabane Marbella Piscina
            </option>
          </optgroup>
        </Form.Select>
      </Form.Group>
    );
  } else {
    // console.log("no admin");
    return (
      <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>{t("home.restaurante")}</Form.Label>
        <Form.Select
          aria-label="Restaurant"
          value={props.selectRestaurant}
          onChange={props.handleOnChangeselect}
          disabled
        >
          <option disabled selected value>
            Elige una opción
          </option>
          <optgroup label="Lobito">
            <option value="LobitoMarbella">Lobito Marbella</option>
            <option value="LobitoMadrid">Lobito Madrid</option>
            <option value="LobitoDoha">Lobito Doha</option>
            <option value="LobitoLaFinca">Lobito La Finca</option>
          </optgroup>
          <optgroup label="BIBO">
            <option value="BIBOMarbella">BIBO Marbella</option>
            <option value="BIBODOHA">BIBO DOHA</option>
            <option value="BIBOMadrid">BIBO Madrid</option>
            <option value="BIBOTarifa">BIBO Tarifa</option>
            <option value="BIBOIbiza">BIBO Ibiza</option>
            <option value="BIBOLondon">BIBO London</option>
          </optgroup>
          <optgroup label="Leña">
            <option value="LenaMarbella">Leña Marbella</option>
            <option value="LenaMadrid">Leña Madrid</option>
            <option value="LenaDubai">Leña Dubai</option>
            <option value="LenaBarcelona">Leña Barcelona</option>
          </optgroup>
          <optgroup label="Smoked Room">
            <option value="SmokedRoom">Smoked Room Madrid</option>
            <option value="SmokedRoomDubai">Smoked Room Dubai</option>
          </optgroup>
          <optgroup label="Babette">
            <option value="BabetteMarbella">Babette Marbella</option>
          </optgroup>
          <optgroup label="CasaDani">
            <option value="CasaDaniNY">Casa Dani NY</option>
          </optgroup>
          <optgroup label="PolloVerde">
            <option value="PolloVerdeNY">Pollo Verde NY</option>
          </optgroup>
          <optgroup label="Alelí">
            <option value="AleliMarbella">Alelí Marbella</option>
            <option value="AleliBudapest">Alelí Budapest</option>
          </optgroup>
          <optgroup label="Kemuri">
            <option value="KemuriMarbella">Kemuri Marbella</option>
          </optgroup>
          {/* <optgroup label="Kurai">
            <option value="KuraiMarbella">Kurai Marbella</option>
          </optgroup> */}
          <optgroup label="Tragabuches">
            <option value="TragabuchesMarbella">Tragabuches Marbella</option>
            <option value="TragabuchesMadrid">Tragabuches Madrid</option>
          </optgroup>
          <optgroup label="Coleccionista">
            <option value="ColeccionistaMadrid">El Coleccionista Madrid</option>
          </optgroup>
          <optgroup label="Lacabane">
            <option value="LacabaneMarbella">La Cabane Marbella</option>
            <option value="PiscinaLacabaneMarbella">
              La Cabane Marbella Piscina
            </option>
          </optgroup>
        </Form.Select>
      </Form.Group>
    );
  }
}

// const Traduccion = withTranslation()(SelectRestaurantes);
function Restaurantes(props) {
  const [selectRestaurant, setSelectRestaurant] = React.useState();
  const [datosquery, setDatosquery] = React.useState([]);
  const [admin, setAdmin] = React.useState(false);
  const [totalcoverL, setTotalcoverL] = React.useState(0);
  const [totalcoverD, setTotalcoverD] = React.useState(0);
  const [totalrevenueL, setTotalrevenueL] = React.useState(0);
  const [totalrevenueD, setTotalrevenueD] = React.useState(0);
  const [totalpropinaL, setTotalpropinaL] = React.useState(0);
  const [totalpropinaD, setTotalpropinaD] = React.useState(0);
  const [cierreReservasD, setCierreReservasD] = React.useState(0);
  const [cierreReservasL, setCierreReservasL] = React.useState(0);
  const [show, setShow] = useState(false);
  const [totalcoverB, setTotalcoverB] = React.useState(0);
  const [totalrevenueB, setTotalrevenueB] = React.useState(0);
  const [totalpropinaB, setTotalpropinaB] = React.useState(0);
  const [cierreReservasB, setCierreReservasB] = React.useState(0);

  const [totalrevenueBFood, setTotalrevenueBFood] = React.useState(0);
  const [totalrevenueLFood, setTotalrevenueLFood] = React.useState(0);
  const [totalrevenueDFood, setTotalrevenueDFood] = React.useState(0);
  const [totalrevenueBDrinks, setTotalrevenueBDrinks] = React.useState(0);
  const [totalrevenueLDrinks, setTotalrevenueLDrinks] = React.useState(0);
  const [totalrevenueDDrinks, setTotalrevenueDDrinks] = React.useState(0);

  const [serviceChargeB, setServiceChargeB] = React.useState(0);
  const [serviceChargeL, setServiceChargeL] = React.useState(0);
  const [serviceChargeD, setServiceChargeD] = React.useState(0);
  const [variosB, setVariosB] = React.useState(0);
  const [variosL, setVariosL] = React.useState(0);
  const [variosD, setVariosD] = React.useState(0);

  const [hamacasB, setHamacasB] = React.useState(0);
  const [hamacasL, setHamacasL] = React.useState(0);
  const [hamacasD, setHamacasD] = React.useState(0);

  const [invitacionB, setInvitacionB] = React.useState(0);
  const [invitacionL, setInvitacionL] = React.useState(0);
  const [invitacionD, setInvitacionD] = React.useState(0);

  const notify = () =>
    toast.success(t("home.toastOk"), {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      showDuration: 2000,
      draggable: false,
      progress: undefined,
      onClose: () => window.location.replace("/"),
    });
  const notifyerror = (error) =>
    toast.danger("Error" + error, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      // onClose: () => window.location.replace("/"),
    });
  const subirDatosIncidencia = async (e) => {
    const fechacreado = Date.now();
    const hoy = new Date(fechacreado);
    try {
      if (selectRestaurant == "") {
      } else {
        const docRef = await addDoc(collection(db, selectRestaurant), {
          restaurant: selectRestaurant,
          weather: e.weather,
          managerL: e.managerL,
          coverL: e.coverL != null ? e.coverL : 0,
          totalnetrevenueL: totalrevenueL != null ? totalrevenueL : 0,
          vipL: e.vipL,
          regL: e.regL,
          walkinsL: e.walkinsL,
          commentsL: e.commentsL,
          managerD: e.managerD,
          coverD: e.coverD != null ? e.coverD : 0,
          totalnetrevenueD: totalrevenueD != null ? totalrevenueD : 0,
          vipD: e.vipD,
          regD: e.regD,
          walkinsD: e.walkinsD,
          commentsD: e.commentsD,
          propinasD: e.propinasD,
          propinasL: e.propinasL,
          incidenciaId: uuidv4(),
          noshowD: e.noshowD,
          noshowL: e.noshowL,
          habitualesD: e.habitualesD,
          habitualesL: e.habitualesL,
          incidentesD: e.incidentesD,
          incidentesL: e.incidentesL,
          fecha: Timestamp.now(),
          kuraiL: e.kuraiL != null ? e.kuraiL : 0,
          kuraiD: e.kuraiD != null ? e.kuraiD : 0,
          cierreReservasL: e.cierreReservasL,
          cierreReservasD: e.cierreReservasD,
          MotivoCierreReservasL: e.MotivoCierreReservasL,
          MotivoCierreReservasD: e.MotivoCierreReservasD,
          creado: datevalue.toLocaleDateString("es-ES"),
          hora: datevalue.toTimeString(),
          managerB: e.managerB != null ? e.managerB : "",
          coverB: e.coverB != null ? e.coverB : 0,
          totalnetrevenueB: totalrevenueB != null ? totalrevenueB : 0,
          propinasB: e.propinasB != null ? e.propinasB : "",
          habitualesB: e.habitualesB != null ? e.habitualesB : "",
          vipB: e.vipB != null ? e.vipB : "",
          regB: e.regB != null ? e.regB : "",
          walkinsB: e.walkinsB != null ? e.walkinsB : "",
          noshowB: e.noshowB != null ? e.noshowB : "",
          incidentesB: e.incidentesB != null ? e.incidentesB : "",
          cierreReservasB: e.cierreReservasB != null ? e.cierreReservasB : "",
          MotivoCierreReservasB:
            e.MotivoCierreReservasB != null ? e.MotivoCierreReservasB : "",
          commentsB: e.commentsB != null ? e.commentsB : "",
          bartendersB: e.bartendersB != null ? e.bartendersB : "",
          serversB: e.serversB != null ? e.serversB : "",
          bussersB: e.bussersB != null ? e.bussersB : "",
          sommB: e.sommB != null ? e.sommB : "",
          polisherB: e.polisherB != null ? e.polisherB : "",
          hostB: e.hostB != null ? e.hostB : "",
          bartendersL: e.bartendersL != null ? e.bartendersL : "",
          serversL: e.serversL != null ? e.serversL : "",
          bussersL: e.bussersL != null ? e.bussersL : "",
          sommL: e.sommL != null ? e.sommL : "",
          polisherL: e.polisherL != null ? e.polisherL : "",
          hostL: e.hostL != null ? e.hostL : "",
          bartendersD: e.bartendersD != null ? e.bartendersD : "",
          serversD: e.serversD != null ? e.serversD : "",
          bussersD: e.bussersD != null ? e.bussersD : "",
          sommD: e.sommD != null ? e.sommD : "",
          polisherD: e.polisherD != null ? e.polisherD : "",
          hostD: e.hostD != null ? e.hostD : "",
          totalnetrevenueFoodL:
            e.totalnetrevenueFoodL != null ? e.totalnetrevenueFoodL : 0,
          totalnetrevenueDrinksL:
            e.totalnetrevenueDrinksL != null ? e.totalnetrevenueDrinksL : 0,
          totalnetrevenueFoodB:
            e.totalnetrevenueFoodB != null ? e.totalnetrevenueFoodB : 0,
          totalnetrevenueDrinksB:
            e.totalnetrevenueDrinksB != null ? e.totalnetrevenueDrinksB : 0,
          totalnetrevenueFoodD:
            e.totalnetrevenueFoodD != null ? e.totalnetrevenueFoodD : 0,
          totalnetrevenueDrinksD:
            e.totalnetrevenueDrinksD != null ? e.totalnetrevenueDrinksD : 0,
          serviceChargeB: e.serviceChargeB != null ? e.serviceChargeB : 0,
          serviceChargeL: e.serviceChargeL != null ? e.serviceChargeL : 0,
          serviceChargeD: e.serviceChargeD != null ? e.serviceChargeD : 0,
          variosB: e.variosB != null ? e.variosB : 0,
          variosL: e.variosL != null ? e.variosL : 0,
          variosD: e.variosD != null ? e.variosD : 0,
          hamacasB: e.hamacasB != null ? e.hamacasB : 0,
          hamacasL: e.hamacasL != null ? e.hamacasL : 0,
          hamacasD: e.hamacasD != null ? e.hamacasD : 0,

          invitacionB: e.invitacionB != null ? e.invitacionB : 0,
          invitacionL: e.invitacionL != null ? e.invitacionL : 0,
          invitacionD: e.invitacionD != null ? e.invitacionD : 0,
        }).then(
          (response) => {
            console.log(response);
            notify();
          },
          (error) => {
            console.error(error);
          }
        );

        // console.log(docRef);
      }
    } catch (e) {
      console.error("Error adding document: ", e);
      notifyerror(notifyerror(e));
    }
  };

  const handleOnChangeselect = (e) => {
    e.preventDefault();

    setSelectRestaurant(e.target.value);
  };

  const auth = getAuth();
  const user = auth.currentUser;

  async function rolequery() {
    // onsole.log("fecha date:" + fecha);

    const q = query(
      collection(db, "user"),
      where("uid", "==", user !== null ? user.uid : "")
    );
    const cities = [];

    const querySnapshot = await getDocs(q);
    // console.log("hola");
    querySnapshot.forEach((doc) => {
      var encontrado = false;

      cities.forEach((element) => {
        if (element.uid == doc.data().uid) {
          encontrado = true;
        }
      });
      if (!encontrado) {
        cities.push(doc.data());
      }
    });
    // console.log(cities);

    setDatosquery(cities);

    if (cities.length != 0) {
      if (cities[0].rol == "Admin") {
        setAdmin(true);
      } else {
        setAdmin(false);
      }
      setSelectRestaurant(cities[0].idrestaurante);
    }
  }
  const fechaDeAyer = () => {
    let hoy = new Date();
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let ayer = new Date(hoy.getTime() - DIA_EN_MILISEGUNDOS);
    return ayer;
  };
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const safeParse = (value) => {
    const num = parseFloat(value);
    return isNaN(num) ? 0 : num;
  };

  const avtotal = () => {
    let cover =
    safeParse(totalcoverD) +
    safeParse(totalcoverL) +
    safeParse(totalcoverB);
    let revenue =
    safeParse(totalrevenueL) +
    safeParse(totalrevenueD) +
    safeParse(totalrevenueB);

    let hamacas =
    safeParse(hamacasB) + safeParse(hamacasL) + safeParse(hamacasD);
    let varios =
    safeParse(variosB) + safeParse(variosL) + safeParse(variosD);

    let serviceCharge =
    safeParse(serviceChargeB) +
    safeParse(serviceChargeL) +
    safeParse(serviceChargeD);

    let av = (revenue + hamacas + varios + serviceCharge) / cover;
    // console.log(cover + " + " + revenue);
    return av.toFixed(2);
  };
  const [datevalue, dateonChange] = useState(fechaDeAyer());

  const { t, i18n } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };
  const totalRevenue = () => {
    let trevenueL,
      trevenueD,
      trevenueB = 0;

    trevenueL = safeParse(totalrevenueL);
    trevenueD = safeParse(totalrevenueD);
    trevenueB = safeParse(totalrevenueB);

    let total = trevenueL + trevenueB + trevenueD;

    return safeParse(total.toFixed(2));
  };

  useEffect(() => {
    var total = safeParse(totalrevenueLFood) + safeParse(totalrevenueLDrinks);
    setTotalrevenueL(safeParse(total.toFixed(2)));

    return () => {
      setTotalrevenueL(0);
    };
  }, [totalrevenueLFood, totalrevenueLDrinks]);

  useEffect(() => {
    var total = safeParse(totalrevenueDFood) + safeParse(totalrevenueDDrinks);
    setTotalrevenueD(safeParse(total.toFixed(2)));

    return () => {
      setTotalrevenueD(0);
    };
  }, [totalrevenueDFood, totalrevenueDDrinks]);

  useEffect(() => {
    var total = safeParse(totalrevenueBFood) + safeParse(totalrevenueBDrinks);
    setTotalrevenueB(safeParse(total.toFixed(2)));

    return () => {
      setTotalrevenueB(0);
    };
  }, [totalrevenueBFood, totalrevenueBDrinks]);

  const totalPropinas = () => {
    let tPropinasL,
      tPropinasD,
      tPropinasB = 0;

    tPropinasL = safeParse(totalpropinaL);
    tPropinasD = safeParse(totalpropinaD);
    tPropinasB = safeParse(totalpropinaB);

    let total = tPropinasL + tPropinasD + tPropinasB;

    return safeParse(total.toFixed(2));
  };
  const totalServiceCharge = () => {
    let tserviceChargeB,
      tserviceChargeL,
      tserviceChargeD = 0;

    tserviceChargeB = safeParse(serviceChargeB);
    tserviceChargeL = safeParse(serviceChargeL);
    tserviceChargeD = safeParse(serviceChargeD);

    let total = tserviceChargeB + tserviceChargeL + tserviceChargeD;

    return safeParse(total.toFixed(2));
  };

  const totalVarios = () => {
    let tvariosB,
      tvariosL,
      tvariosD = 0;

    tvariosB = safeParse(variosB);
    tvariosL = safeParse(variosL);
    tvariosD = safeParse(variosD);

    let total = tvariosB + tvariosL + tvariosD;

    return safeParse(total.toFixed(2));
  };

  const totalHamacas = () => {
    let thamacasB,
      thamacasL,
      thamacasD = 0;

    thamacasB = safeParse(hamacasB);
    thamacasL = safeParse(hamacasL);
    thamacasD = safeParse(hamacasD);

    let total = thamacasB + thamacasL + thamacasD;

    return safeParse(total.toFixed(2));
  };

  const totalInvitacion = () => {
    let tinvitacionB,
      tinvitacionL,
      tinvitacionD = 0;

    tinvitacionB = safeParse(invitacionB);
    tinvitacionL = safeParse(invitacionL);
    tinvitacionD = safeParse(invitacionD);

    let total = tinvitacionB + tinvitacionL + tinvitacionD;

    return safeParse(total.toFixed(2));
  };

  const formatNumber = (value) => {
    let valor = value;

    // valor = valor.replace(/\./g, "");
    valor = valor.replace(/\,/g, ".");

    return valor;
  };

  useEffect(() => {
    rolequery();
  }, []);
  return (
    <Container className="my-5">
      <ToastContainer autoClose={8000} />
      <Row>
        <Col xs={12}>
          <Card className="p-3">
            <Card.Title>{t("home.title")}</Card.Title>
            <hr></hr>

            <Card.Body>
              <Formik
                validationSchema={schema}
                initialValues={{
                  managerL: "",
                  coverL: 0,
                  totalnetrevenueL: 0,
                  vipL: "",
                  regL: "",
                  walkinsL: "",
                  commentsL: "",
                  managerD: "",
                  coverD: 0,
                  totalnetrevenueD: 0,
                  vipD: "",
                  regD: "",
                  walkinsD: "",
                  commentsD: "",
                  propinasD: 0,
                  propinasL: 0,
                  noshowD: "",
                  noshowL: "",
                  habitualesD: "",
                  habitualesL: "",
                  incidentesD: "",
                  incidentesL: "",
                  cierreReservasL: "No",
                  cierreReservasD: "No",
                  MotivoCierreReservasL: "",
                  MotivoCierreReservasD: "",
                  weather: "",
                  managerB: "",
                  coverB: 0,
                  totalnetrevenueB: 0,
                  propinasB: 0,
                  habitualesB: "",
                  vipB: "",
                  regB: "",
                  walkinsB: "",
                  noshowB: "",
                  incidentesB: "",
                  cierreReservasB: "No",
                  MotivoCierreReservasB: "",
                  commentsB: "",
                  bartendersB: "",
                  serversB: "",
                  bussersB: "",
                  sommB: "",
                  polisherB: "",
                  hostB: "",
                  bartendersL: "",
                  serversL: "",
                  bussersL: "",
                  sommL: "",
                  polisherL: "",
                  hostL: "",
                  bartendersD: "",
                  serversD: "",
                  bussersD: "",
                  sommD: "",
                  polisherD: "",
                  hostD: "",
                  totalnetrevenueFoodL: 0,
                  totalnetrevenueDrinksL: 0,
                  totalnetrevenueFoodB: 0,
                  totalnetrevenueDrinksB: 0,
                  totalnetrevenueFoodD: 0,
                  totalnetrevenueDrinksD: 0,
                  serviceChargeB: 0,
                  serviceChargeL: 0,
                  serviceChargeD: 0,
                  variosB: 0,
                  variosL: 0,
                  variosD: 0,
                  hamacasL: 0,
                  hamacasB: 0,
                  hamacasD: 0,
                  invitacionB: 0,
                  invitacionL: 0,
                  invitacionD: 0,
                }}
              >
                {({ handleChange, values, isSubmitting }) => (
                  <Form>
                    <Row>
                      <Col xs={12} md={4}>
                        <SelectRestaurantes
                          admin={admin}
                          text={t("home.restaurante")}
                          selectRestaurant={selectRestaurant}
                          handleOnChangeselect={handleOnChangeselect}
                        ></SelectRestaurantes>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.selectDay")}</Form.Label>
                          <DatePicker
                            className="fecha_select"
                            onChange={dateonChange}
                            minDate={fechaDeAyer()}
                            maxDate={new Date()}
                            value={datevalue}
                            dateFormat="yyyy/MM/dd"
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          {/* <Form.Label> {t("home.weather")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="weather"
                            value={values.weather}
                            onChange={handleChange}
                          /> */}
                          <Form.Group
                            className="mb-3"
                            controlId="formGroupEmail"
                          >
                            <Form.Label>
                              {t("home.weather")}{" "}
                              {geticonTiempo(values.weather)}
                            </Form.Label>
                            <Form.Select
                              aria-label="weather"
                              name="weather"
                              value={values.weather}
                              onChange={handleChange}
                            >
                              <option disabled selected value="">
                                {t("home.weatherTitle")}
                              </option>
                              <option value="soleado">
                                {" "}
                                {t("home.soleado")}
                              </option>
                              <option value="nublado">
                                {" "}
                                {t("home.nublado")}
                              </option>
                              <option value="lluvioso">
                                {" "}
                                {t("home.lluvioso")}
                              </option>
                              <option value="nevado">
                                {" "}
                                {t("home.nevado")}
                              </option>
                            </Form.Select>
                          </Form.Group>
                        </Form.Group>
                      </Col>
                    </Row>
                    {selectRestaurant === "TragabuchesMarbella" ||
                    selectRestaurant === "TragabuchesMadrid" ||
                    selectRestaurant === "LenaMadrid" ||
                    selectRestaurant === "BIBOMadrid" ||
                    selectRestaurant === "LenaBarcelona" ? (
                      <>
                        <Row>
                          <Col xs={12} className="separator">
                            {selectRestaurant === "LenaMadrid" ||
                            selectRestaurant === "BIBOMadrid" ||
                            selectRestaurant === "LenaBarcelona"
                              ? t("home.brunch")
                              : t("home.breakfast")}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.mang")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="managerB"
                                value={values.managerB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.cover")}</Form.Label>
                              <Form.Control
                                type="number"
                                name="coverB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.coverB}
                                onChange={(e) => {
                                  handleChange(e);

                                  setTotalcoverB(
                                    formatNumber(e.currentTarget.value)
                                  );
                                }}
                              />
                            </Form.Group>
                          </Col>

                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>
                                {t("home.totalnetrevenueFood")}
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="totalnetrevenueFoodB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.totalnetrevenueFoodB}
                                onChange={(e) => {
                                  handleChange(e);
                                  setTotalrevenueBFood(
                                    formatNumber(e.currentTarget.value)
                                  );
                                }}
                              />
                              <Form.Text id="passwordHelpBlock" muted>
                                {t("home.helpComma")}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>
                                {t("home.totalnetrevenueDrinks")}
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="totalnetrevenueDrinksB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.totalnetrevenueDrinksB}
                                onChange={(e) => {
                                  handleChange(e);

                                  setTotalrevenueBDrinks(
                                    formatNumber(e.currentTarget.value)
                                  );
                                }}
                              />
                              <Form.Text id="passwordHelpBlock" muted>
                                {t("home.helpComma")}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>
                                {t("home.totalNetRevenue")}
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="totalnetrevenueB"
                                // onFocus={(e) => {
                                //   e.currentTarget.value = "";
                                // }}
                                // on
                                value={totalrevenueB}
                                // onChange={(e) => {
                                //   // handleChange(e);

                                //   setTotalrevenueB(
                                //     formatNumber(e.currentTarget.value)
                                //   );
                                // }}
                                disabled
                              />
                              <Form.Text id="passwordHelpBlock" muted>
                                {t("home.helpSuma")}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.propinas")}</Form.Label>
                              <Form.Control
                                type="number"
                                name="propinasB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.propinasB}
                                onChange={(e) => {
                                  handleChange(e);

                                  setTotalpropinaB(
                                    formatNumber(e.currentTarget.value)
                                  );
                                }}
                              />
                              <Form.Text id="passwordHelpBlock" muted>
                                {t("home.helpComma")}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.varios")}</Form.Label>
                              <Form.Control
                                type="number"
                                name="variosB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.variosB}
                                onChange={(e) => {
                                  handleChange(e);

                                  setVariosB(
                                    formatNumber(e.currentTarget.value)
                                  );
                                }}
                              />
                              <Form.Text id="passwordHelpBlock" muted>
                                {t("home.helpComma")}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.invitacion")}</Form.Label>
                              <Form.Control
                                type="number"
                                name="invitacionB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.invitacionB}
                                onChange={(e) => {
                                  handleChange(e);

                                  setInvitacionB(
                                    formatNumber(e.currentTarget.value)
                                  );
                                }}
                              />
                              <Form.Text id="passwordHelpBlock" muted>
                                {t("home.helpComma")}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          {selectRestaurant === "LacabaneMarbella" ||
                          selectRestaurant === "KemuriMarbella" ? (
                            <Col xs={12} md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="formGroupEmail"
                              >
                                <Form.Label>{t("home.hamacas")}</Form.Label>
                                <Form.Control
                                  type="number"
                                  name="hamacasB"
                                  onFocus={(e) => {
                                    e.currentTarget.value = "";
                                  }}
                                  value={values.hamacasB}
                                  onChange={(e) => {
                                    handleChange(e);

                                    setHamacasB(
                                      formatNumber(e.currentTarget.value)
                                    );
                                  }}
                                />
                                <Form.Text id="passwordHelpBlock" muted>
                                  {t("home.helpComma")}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          ) : (
                            <Col xs={12} md={12}></Col>
                          )}
                          {selectRestaurant === "LacabaneMarbella" ||
                          selectRestaurant === "KemuriMarbella" ? (
                            <Col xs={12} md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="formGroupEmail"
                              >
                                <Form.Label>
                                  {t("home.servicecharge")}
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="serviceChargeB"
                                  onFocus={(e) => {
                                    e.currentTarget.value = "";
                                  }}
                                  value={values.serviceChargeB}
                                  onChange={(e) => {
                                    handleChange(e);

                                    setServiceChargeB(
                                      formatNumber(e.currentTarget.value)
                                    );
                                  }}
                                />
                                <Form.Text id="passwordHelpBlock" muted>
                                  {t("home.helpComma")}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          ) : (
                            <Col xs={12} md={12}></Col>
                          )}
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.habituales")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="habitualesB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.habitualesB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.vips")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="vipB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.vipB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.invitaciones")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="regB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.regB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.walkins")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="walkinsB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.walkinsB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.noshow")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="noshowB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.noshowB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>

                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.problem")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="incidentesB"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.incidentesB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          {selectRestaurant !== "LenaDubai" ? (
                            <Col xs={12} md={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="formGroupEmailB"
                              >
                                <Form.Label>
                                  {t("home.cierreReservas")}
                                </Form.Label>
                                <Form.Select
                                  // aria-label="Default select example"
                                  name="cierreReservasB"
                                  value={cierreReservasB}
                                  onChange={(e) => {
                                    handleChange(e);

                                    setCierreReservasB(e.currentTarget.value);
                                  }}
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                </Form.Select>
                                {/* <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                            name="commentsL"
                            maxLength="1320"
                            value={values.commentsL}
                            onChange={handleChange}
                          /> */}
                              </Form.Group>
                            </Col>
                          ) : (
                            <></>
                          )}
                          {cierreReservasB == "Si" ? (
                            <Col xs={12} md={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="formGroupEmaiB"
                              >
                                <Form.Label>
                                  {t("home.MotivoCierreReservas")}
                                </Form.Label>
                                {/* <Form.Select
                              // aria-label="Default select example"
                              name="cierreReservasD"
                              value={cierreReservasD}
                              onChange={(e) => {
                                handleChange(e);

                                setCierreReservasD(e.currentTarget.value);
                              }}
                            >
                              <option value="No">No</option>
                              <option value="Si">Si</option>
                            </Form.Select> */}
                                <Form.Control
                                  as="textarea"
                                  style={{ height: "100px" }}
                                  name="MotivoCierreReservasB"
                                  // maxLength="1320"
                                  value={values.MotivoCierreReservasB}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </Col>
                          ) : (
                            <></>
                          )}
                          {/* {selectRestaurant == "KemuriMarbella" ? (
                        <Col xs={12} md={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="formGroupEmail"
                          >
                            <Form.Label>Kurai</Form.Label>
                            <Form.Control
                              as="textarea"
                              // placeholder="Leave a comment here"
                              style={{ height: "100px" }}
                              name="kuraiL"
                              maxLength="1320"
                              value={values.kuraiL}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      ) : (
                        <></>
                      )} */}

                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.bartenders")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="bartendersB"
                                value={values.bartendersB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.servers")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="serversB"
                                value={values.serversB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.bussers")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="bussersB"
                                value={values.bussersB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.somm")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="sommB"
                                value={values.sommB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          {selectRestaurant !== "LenaDubai" ? (
                            <Col xs={12} md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="formGroupEmail"
                              >
                                <Form.Label>{t("home.polisher")}</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="polisherB"
                                  value={values.polisherB}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </Col>
                          ) : (
                            <></>
                          )}
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.host")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="hostB"
                                value={values.hostB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.hostess")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="hostessB"
                                value={values.hostessB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.comentarios")}</Form.Label>
                              <Form.Control
                                as="textarea"
                                style={{ height: "100px" }}
                                name="commentsB"
                                // maxLength="1320"
                                value={values.commentsB}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}
                    <Row>
                      <Col xs={12} className="separator">
                        {selectRestaurant !== "LacabaneMarbella" &&
                        selectRestaurant !== "AleliBudapest" &&
                        selectRestaurant !== "BIBOMarbella" &&
                        selectRestaurant !== "SmokedRoomDubai"
                          ? t("home.lunch")
                          : "Report"}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.mang")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="managerL"
                            value={values.managerL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.cover")}</Form.Label>
                          <Form.Control
                            type="number"
                            name="coverL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.coverL}
                            onChange={(e) => {
                              handleChange(e);

                              setTotalcoverL(
                                formatNumber(e.currentTarget.value)
                              );
                            }}
                          />
                        </Form.Group>
                      </Col>

                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>
                            {t("home.totalnetrevenueFood")}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="totalnetrevenueFoodL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.totalnetrevenueFoodL}
                            onChange={(e) => {
                              handleChange(e);
                              setTotalrevenueLFood(
                                formatNumber(e.currentTarget.value)
                              );
                            }}
                          />
                          <Form.Text id="passwordHelpBlock" muted>
                            {t("home.helpComma")}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>
                            {t("home.totalnetrevenueDrinks")}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="totalnetrevenueDrinksL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.totalnetrevenueDrinksL}
                            onChange={(e) => {
                              handleChange(e);

                              setTotalrevenueLDrinks(
                                formatNumber(e.currentTarget.value)
                              );
                            }}
                          />
                          <Form.Text id="passwordHelpBlock" muted>
                            {t("home.helpComma")}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.totalNetRevenue")}</Form.Label>
                          <Form.Control
                            type="number"
                            name="totalnetrevenueL"
                            // onFocus={(e) => {
                            //   e.currentTarget.value = "";
                            // }}
                            // on
                            value={totalrevenueL}
                            // onChange={(e) => {
                            //   handleChange(e);

                            //   setTotalrevenueL(
                            //     formatNumber(e.currentTarget.value)
                            //   );
                            // }}
                            disabled
                          />
                          <Form.Text id="passwordHelpBlock" muted>
                            {t("home.helpSuma")}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.propinas")}</Form.Label>
                          <Form.Control
                            type="number"
                            name="propinasL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.propinasL}
                            onChange={(e) => {
                              handleChange(e);

                              setTotalpropinaL(
                                formatNumber(e.currentTarget.value)
                              );
                            }}
                          />
                          <Form.Text id="passwordHelpBlock" muted>
                            {t("home.helpComma")}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.varios")}</Form.Label>
                          <Form.Control
                            type="number"
                            name="variosL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.variosL}
                            onChange={(e) => {
                              handleChange(e);

                              setVariosL(formatNumber(e.currentTarget.value));
                            }}
                          />
                          <Form.Text id="passwordHelpBlock" muted>
                            {t("home.helpComma")}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.invitacion")}</Form.Label>
                          <Form.Control
                            type="number"
                            name="invitacionL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.invitacionL}
                            onChange={(e) => {
                              handleChange(e);

                              setInvitacionL(
                                formatNumber(e.currentTarget.value)
                              );
                            }}
                          />
                          <Form.Text id="passwordHelpBlock" muted>
                            {t("home.helpComma")}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      {selectRestaurant === "LacabaneMarbella" ||
                      selectRestaurant === "KemuriMarbella" ? (
                        <Col xs={12} md={4}>
                          <Form.Group
                            className="mb-3"
                            controlId="formGroupEmail"
                          >
                            <Form.Label>{t("home.hamacas")}</Form.Label>
                            <Form.Control
                              type="number"
                              name="hamacasL"
                              onFocus={(e) => {
                                e.currentTarget.value = "";
                              }}
                              value={values.hamacasL}
                              onChange={(e) => {
                                handleChange(e);

                                setHamacasL(
                                  formatNumber(e.currentTarget.value)
                                );
                              }}
                            />
                            <Form.Text id="passwordHelpBlock" muted>
                              {t("home.helpComma")}
                            </Form.Text>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xs={12} md={12}></Col>
                      )}
                      {selectRestaurant === "LacabaneMarbella" ||
                      selectRestaurant === "KemuriMarbella" ? (
                        <Col xs={12} md={4}>
                          <Form.Group
                            className="mb-3"
                            controlId="formGroupEmail"
                          >
                            <Form.Label>{t("home.servicecharge")}</Form.Label>
                            <Form.Control
                              type="number"
                              name="serviceChargeL"
                              onFocus={(e) => {
                                e.currentTarget.value = "";
                              }}
                              value={values.serviceChargeL}
                              onChange={(e) => {
                                handleChange(e);

                                setServiceChargeL(
                                  formatNumber(e.currentTarget.value)
                                );
                              }}
                            />
                            <Form.Text id="passwordHelpBlock" muted>
                              {t("home.helpComma")}
                            </Form.Text>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xs={12} md={12}></Col>
                      )}
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.habituales")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="habitualesL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.habitualesL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.vips")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="vipL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.vipL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.invitaciones")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="regL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.regL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.walkins")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="walkinsL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.walkinsL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.noshow")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="noshowL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.noshowL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.problem")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="incidentesL"
                            onFocus={(e) => {
                              e.currentTarget.value = "";
                            }}
                            value={values.incidentesL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      {selectRestaurant !== "LenaDubai" ? (
                        <Col xs={12} md={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="formGroupEmail"
                          >
                            <Form.Label>{t("home.cierreReservas")}</Form.Label>
                            <Form.Select
                              // aria-label="Default select example"
                              name="cierreReservasD"
                              value={cierreReservasD}
                              onChange={(e) => {
                                handleChange(e);

                                setCierreReservasD(e.currentTarget.value);
                              }}
                            >
                              <option value="No">No</option>
                              <option value="Si">Si</option>
                            </Form.Select>
                            {/* <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                            name="commentsL"
                            maxLength="1320"
                            value={values.commentsL}
                            onChange={handleChange}
                          /> */}
                          </Form.Group>
                        </Col>
                      ) : (
                        <></>
                      )}
                      {cierreReservasL == "Si" ? (
                        <Col xs={12} md={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="formGroupEmail"
                          >
                            <Form.Label>
                              {t("home.MotivoCierreReservas")}
                            </Form.Label>
                            {/* <Form.Select
                              // aria-label="Default select example"
                              name="cierreReservasD"
                              value={cierreReservasD}
                              onChange={(e) => {
                                handleChange(e);

                                setCierreReservasD(e.currentTarget.value);
                              }}
                            >
                              <option value="No">No</option>
                              <option value="Si">Si</option>
                            </Form.Select> */}
                            <Form.Control
                              as="textarea"
                              style={{ height: "100px" }}
                              name="MotivoCierreReservasD"
                              // maxLength="1320"
                              value={values.MotivoCierreReservasD}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      ) : (
                        <></>
                      )}
                      {/* {selectRestaurant == "KemuriMarbella" ? (
                        <Col xs={12} md={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="formGroupEmail"
                          >
                            <Form.Label>Kurai</Form.Label>
                            <Form.Control
                              as="textarea"
                              // placeholder="Leave a comment here"
                              style={{ height: "100px" }}
                              name="kuraiL"
                              maxLength="1320"
                              value={values.kuraiL}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      ) : (
                        <></>
                      )} */}
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.bartenders")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="bartendersL"
                            value={values.bartendersL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.servers")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="serversL"
                            value={values.serversL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.bussers")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="bussersL"
                            value={values.bussersL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={3}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.somm")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="sommL"
                            value={values.sommL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      {selectRestaurant !== "LenaDubai" ? (
                        <Col xs={12} md={4}>
                          <Form.Group
                            className="mb-3"
                            controlId="formGroupEmail"
                          >
                            <Form.Label>{t("home.polisher")}</Form.Label>
                            <Form.Control
                              type="text"
                              name="polisherL"
                              value={values.polisherL}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      ) : (
                        <></>
                      )}
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.host")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="hostL"
                            value={values.hostL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.hostess")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="hostessL"
                            value={values.hostessL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>{t("home.comentarios")}</Form.Label>
                          <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                            name="commentsL"
                            // maxLength="1320"
                            value={values.commentsL}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {selectRestaurant !== "LacabaneMarbella" &&
                    selectRestaurant !== "AleliBudapest" &&
                    selectRestaurant !== "BIBOMarbella" &&
                    selectRestaurant !== "SmokedRoomDubai" ? (
                      <>
                        <Row>
                          <Col xs={12} className="separator">
                            {t("home.dinner")}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.mang")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="managerD"
                                value={values.managerD}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.cover")}</Form.Label>
                              <Form.Control
                                type="number"
                                name="coverD"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.coverD}
                                onChange={(e) => {
                                  handleChange(e);
                                  // let cover =
                                  //   totalcover + safeParse( e.currentTarget.value);
                                  setTotalcoverD(
                                    formatNumber(e.currentTarget.value)
                                  );
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>
                                {t("home.totalnetrevenueFood")}
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="totalnetrevenueFoodD"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.totalnetrevenueFoodD}
                                onChange={(e) => {
                                  handleChange(e);
                                  setTotalrevenueDFood(
                                    formatNumber(e.currentTarget.value)
                                  );
                                }}
                              />
                              <Form.Text id="passwordHelpBlock" muted>
                                {t("home.helpComma")}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>
                                {t("home.totalnetrevenueDrinks")}
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="totalnetrevenueDrinksD"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.totalnetrevenueDrinksD}
                                onChange={(e) => {
                                  handleChange(e);

                                  setTotalrevenueDDrinks(
                                    formatNumber(e.currentTarget.value)
                                  );
                                }}
                              />
                              <Form.Text id="passwordHelpBlock" muted>
                                {t("home.helpComma")}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>
                                {t("home.totalNetRevenue")}
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="totalnetrevenueD"
                                // onFocus={(e) => {
                                //   e.currentTarget.value = "";
                                // }}
                                // on
                                value={totalrevenueD}
                                // onChange={(e) => {
                                //   handleChange(e);

                                //   setTotalrevenueD(
                                //     formatNumber(e.currentTarget.value)
                                //   );
                                // }}
                                disabled
                              />
                              <Form.Text id="passwordHelpBlock" muted>
                                {t("home.helpSuma")}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.propinas")}</Form.Label>
                              <Form.Control
                                type="number"
                                name="propinasD"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.propinasD}
                                onChange={(e) => {
                                  handleChange(e);

                                  setTotalpropinaD(
                                    formatNumber(e.currentTarget.value)
                                  );
                                }}
                              />
                              <Form.Text id="passwordHelpBlock" muted>
                                {t("home.helpComma")}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.varios")}</Form.Label>
                              <Form.Control
                                type="number"
                                name="variosD"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.variosD}
                                onChange={(e) => {
                                  handleChange(e);

                                  setVariosD(
                                    formatNumber(e.currentTarget.value)
                                  );
                                }}
                              />
                              <Form.Text id="passwordHelpBlock" muted>
                                {t("home.helpComma")}
                              </Form.Text>
                            </Form.Group>
                          </Col>{" "}
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.invitacion")}</Form.Label>
                              <Form.Control
                                type="number"
                                name="invitacionD"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.invitacionD}
                                onChange={(e) => {
                                  handleChange(e);

                                  setInvitacionD(
                                    formatNumber(e.currentTarget.value)
                                  );
                                }}
                              />
                              <Form.Text id="passwordHelpBlock" muted>
                                {t("home.helpComma")}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          {selectRestaurant === "LacabaneMarbella" ||
                          selectRestaurant === "KemuriMarbella" ? (
                            <Col xs={12} md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="formGroupEmail"
                              >
                                <Form.Label>{t("home.hamacas")}</Form.Label>
                                <Form.Control
                                  type="number"
                                  name="hamacasD"
                                  onFocus={(e) => {
                                    e.currentTarget.value = "";
                                  }}
                                  value={values.hamacasD}
                                  onChange={(e) => {
                                    handleChange(e);

                                    setHamacasD(
                                      formatNumber(e.currentTarget.value)
                                    );
                                  }}
                                />
                                <Form.Text id="passwordHelpBlock" muted>
                                  {t("home.helpComma")}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          ) : (
                            <Col xs={12} md={12}></Col>
                          )}
                          {selectRestaurant === "LacabaneMarbella" ||
                          selectRestaurant === "KemuriMarbella" ? (
                            <Col xs={12} md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="formGroupEmail"
                              >
                                <Form.Label>
                                  {t("home.servicecharge")}
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="serviceChargeD"
                                  onFocus={(e) => {
                                    e.currentTarget.value = "";
                                  }}
                                  value={values.serviceChargeD}
                                  onChange={(e) => {
                                    handleChange(e);

                                    setServiceChargeD(
                                      formatNumber(e.currentTarget.value)
                                    );
                                  }}
                                />
                                <Form.Text id="passwordHelpBlock" muted>
                                  {t("home.helpComma")}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          ) : (
                            <Col xs={12} md={12}></Col>
                          )}
                          <hr></hr>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.habituales")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="habitualesD"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.habitualesD}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.vips")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="vipD"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.vipD}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.invitaciones")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="regD"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.regD}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.walkins")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="walkinsD"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.walkinsD}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.noshow")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="noshowD"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.noshowD}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.problem")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="incidentesD"
                                onFocus={(e) => {
                                  e.currentTarget.value = "";
                                }}
                                value={values.incidentesD}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          {selectRestaurant !== "LenaDubai" ? (
                            <Col xs={12} md={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="formGroupEmail"
                              >
                                <Form.Label>
                                  {t("home.cierreReservas")}
                                </Form.Label>
                                <Form.Select
                                  // aria-label="Default select example"
                                  name="cierreReservasL"
                                  value={cierreReservasL}
                                  onChange={(e) => {
                                    handleChange(e);

                                    setCierreReservasL(e.currentTarget.value);
                                  }}
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                </Form.Select>
                                {/* <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                            name="commentsL"
                            maxLength="1320"
                            value={values.commentsL}
                            onChange={handleChange}
                          /> */}
                              </Form.Group>
                            </Col>
                          ) : (
                            <></>
                          )}
                          {/* {selectRestaurant == "KemuriMarbella" ? (
                            <Col xs={12} md={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="formGroupEmail"
                              >
                                <Form.Label>Kurai</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  // placeholder="Leave a comment here"
                                  style={{ height: "100px" }}
                                  name="kuraiD"
                                  // maxLength="1320"
                                  value={values.kuraiD}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </Col>
                          ) : (
                            <></>
                          )} */}
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.bartenders")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="bartendersD"
                                value={values.bartendersD}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.servers")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="serversD"
                                value={values.serversD}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.bussers")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="bussersD"
                                value={values.bussersD}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={3}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.somm")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="sommD"
                                value={values.sommD}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          {selectRestaurant !== "LenaDubai" ? (
                            <Col xs={12} md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="formGroupEmail"
                              >
                                <Form.Label>{t("home.polisher")}</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="polisherD"
                                  value={values.polisherD}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </Col>
                          ) : (
                            <></>
                          )}
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.host")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="hostD"
                                value={values.hostD}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.hostess")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="hostessD"
                                value={values.hostessD}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupEmail"
                            >
                              <Form.Label>{t("home.comentarios")}</Form.Label>
                              <Form.Control
                                as="textarea"
                                // placeholder="Leave a comment here"
                                style={{ height: "100px" }}
                                name="commentsD"
                                // maxLength="1320"
                                value={values.commentsD}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}
                    <Row>
                      <Col xs={12} className="separator">
                        ende
                      </Col>
                    </Row>
                    <Row>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formGroupEmail"
                      >
                        <Form.Label column sm={4} xs={12}>
                          {t("home.tcover")}
                        </Form.Label>
                        <Col sm={8} xs={12}>
                          <Form.Control
                            style={{ textAlign: "right" }}
                            type="text"
                            name="totalcover"
                            value={
                              safeParse(totalcoverD) +
                              safeParse(totalcoverL) +
                              safeParse(totalcoverB)
                            }
                            disabled
                            // onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formGroupEmail"
                      >
                        <Form.Label column sm={4} xs={12}>
                          {t("home.totalNetRevenue")}
                        </Form.Label>
                        <Col sm={8} xs={12}>
                          <Form.Control
                            style={{ textAlign: "right" }}
                            type="text"
                            name="vipL"
                            value={totalRevenue()}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formGroupEmail"
                      >
                        <Form.Label column sm={4} xs={12}>
                          {t("home.av")}
                        </Form.Label>
                        <Col sm={8} xs={12}>
                          <Form.Control
                            style={{ textAlign: "right" }}
                            type="text"
                            name="av"
                            value={avtotal()}
                            disabled
                            // onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formGroupEmail"
                      >
                        <Form.Label column sm={4} xs={12}>
                          {t("home.tpropinas")}
                        </Form.Label>
                        <Col sm={8} xs={12}>
                          <Form.Control
                            type="text"
                            name="totalpropinas"
                            style={{ textAlign: "right" }}
                            value={totalPropinas()}
                            disabled
                          />
                        </Col>
                      </Form.Group>{" "}
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formGroupEmail"
                      >
                        <Form.Label column sm={4} xs={12}>
                          {t("home.tinvitacion")}
                        </Form.Label>
                        <Col sm={8} xs={12}>
                          <Form.Control
                            style={{ textAlign: "right" }}
                            type="text"
                            name="tinvitacion"
                            value={totalInvitacion()}
                            disabled
                            // onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                      {selectRestaurant === "LacabaneMarbella" ||
                      selectRestaurant === "KemuriMarbella" ? (
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formGroupEmail"
                        >
                          <Form.Label column sm={4} xs={12}>
                            {t("home.tservicecharge")}
                          </Form.Label>
                          <Col sm={8} xs={12}>
                            <Form.Control
                              type="text"
                              name="totalservice"
                              style={{ textAlign: "right" }}
                              value={totalServiceCharge()}
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      ) : (
                        <></>
                      )}
                      {selectRestaurant === "LacabaneMarbella" ||
                      selectRestaurant === "KemuriMarbella" ? (
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formGroupEmail"
                        >
                          <Form.Label column sm={4} xs={12}>
                            {t("home.thamacas")}
                          </Form.Label>
                          <Col sm={8} xs={12}>
                            <Form.Control
                              type="text"
                              name="totalhamacas"
                              style={{ textAlign: "right" }}
                              value={totalHamacas()}
                              disabled
                            />
                          </Col>
                        </Form.Group>
                      ) : (
                        <></>
                      )}
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formGroupEmail"
                      >
                        <Form.Label column sm={4} xs={12}>
                          {t("home.tvarios")}
                        </Form.Label>
                        <Col sm={8} xs={12}>
                          <Form.Control
                            type="text"
                            name="totalvarios"
                            style={{ textAlign: "right" }}
                            value={totalVarios()}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formGroupEmail"
                      >
                        <Form.Label column sm={4} xs={12}>
                          {t("home.total")}
                        </Form.Label>
                        <Col sm={8} xs={12}>
                          <Form.Control
                            type="text"
                            name="totalgeneral"
                            style={{ textAlign: "right" }}
                            value={
                              totalVarios() +
                              totalHamacas() +
                              totalRevenue() +
                              totalServiceCharge()
                            }
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Row>
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => {
                        isSubmitting = true;
                        confirmAlert({
                          title: t("home.warningtitle"),
                          message: t("home.warningdesc"),
                          buttons: [
                            {
                              label: t("home.enviar"),
                              onClick: (e) => subirDatosIncidencia(values),
                            },
                            {
                              label: t("home.cancelar"),
                              onClick: () => (isSubmitting = false),
                            },
                          ],
                        });
                      }}
                      className="w-100 my-3"
                      disabled={isSubmitting}
                    >
                      {t("home.enviar")}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Restaurantes;
